import React, { useState } from 'react';
import { usePlayerField } from '../../hooks/hooks';
import AllItems from './AllItems2';
import CombatGearRightPanel from './Combat/CombatGearRightPanel';
import Loadouts from './Combat/Loadout/Loadouts';
import { ErrorBoundary } from './ErrorBoundary';
import './NavigationTabs.css';
import './RightPanel.css';
import SocialTab from './SocialTab';

type TRightPanelTab = 'AllItems' | 'CombatGear' | 'SocialTab';

function RightPanel() {
	const [selectedTab, setSelectedTab] = useState<TRightPanelTab>('AllItems');
	const partyInvites = usePlayerField('knownInvites').length;

	let socialIcon = (
		<img className='nav-tab-icon icon-border' src='/images/ui/social_icon.png' alt='Social Icon, No Invite' />
	);
	if (partyInvites > 0)
		socialIcon = (
			<img
				className='nav-tab-icon icon-border'
				src='/images/ui/social_icon_notification.png'
				alt='Social Icon, Open Invites'
			/>
		);

	function getTab() {
		switch (selectedTab) {
			case 'AllItems':
				return <AllItems />;
			case 'CombatGear':
				return <CombatGearRightPanel />;
			case 'SocialTab':
				return <SocialTab />;
		}
	}

	return (
		<div className='game-right-panel'>
			<div className='nav-tab-container right'>
				<div
					className={'nav-tab' + (selectedTab === 'AllItems' ? ' selected-tab' : '')}
					onClick={() => setSelectedTab('AllItems')}
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/inventory_icon.png' alt='Inventory' />
					Inventory
				</div>
				<div
					className={'nav-tab' + (selectedTab === 'CombatGear' ? ' selected-tab' : '')}
					onClick={() => setSelectedTab('CombatGear')}
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/equipment_icon.png' alt='Equipment' />
					Equipment
				</div>
				<div
					className={'nav-tab' + (selectedTab === 'SocialTab' ? ' selected-tab' : '')}
					onClick={() => setSelectedTab('SocialTab')}
				>
					{socialIcon}
					Social
				</div>
			</div>
			<div className='right-panel-container idlescape-container'>
				<div className='right-panel-content'>
					<ErrorBoundary>{getTab()}</ErrorBoundary>
				</div>
			</div>
		</div>
	);
}

export default RightPanel;
