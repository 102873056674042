import { Box, Flex, Heading, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsArrow90DegDown } from 'react-icons/bs';
import { usePlayerField } from '../../../../../hooks/hooks';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { socket } from '../../../../../services/socket.service';
import { getAugmentNameColor, socketEmitDebounced } from '../../../../../helper/helperFunctions';
import { settingsList } from '../../../../../utils/settingsList';

export default function AugmentingQueueSettings() {
	const settings = usePlayerField('settings');
	const [sortSuccessfulToStart, setSortSuccessfulToStart] = useState(settings.augmenting.sortSuccessfulToStart);
	const maxPossibleAugmentLevel = settingsList['augmenting.maxAugmentLevel'].max;
	const [maxAugmentLevel, setMaxAugmentLevel] = useState(settings.augmenting.maxAugmentLevel);

	const isMobile = useIsMobile();

	function updateMaxAugmentLevel(value: number) {
		socketEmitDebounced('settings:update', { value: value, path: 'augmenting.maxAugmentLevel' });
		setMaxAugmentLevel(value);
	}

	function toggleSortSuccessfulToStart() {
		socket.emit('settings:update', {
			value: !sortSuccessfulToStart,
			path: 'augmenting.sortSuccessfulToStart',
		});
		setSortSuccessfulToStart(!sortSuccessfulToStart);
	}

	function toggleButton(
		active: boolean,
		onChange: () => void,
		activeIcon: React.ReactElement,
		inactiveIcon: React.ReactElement,
		text: string,
		variant: string
	) {
		return (
			<IdlescapeButton
				variant={variant}
				size='medium'
				onClick={onChange}
				rightIcon={active ? activeIcon : inactiveIcon}
			>
				{text}
			</IdlescapeButton>
		);
	}

	return (
		<Box order={isMobile ? '-1' : ''}>
			<Heading size={'md'} textAlign='center'>
				Maximum Item Level
			</Heading>
			<Flex flexDirection='column' alignItems='center'>
				<Flex alignItems='center' gap='5px'>
					<Slider
						defaultValue={maxAugmentLevel}
						max={maxPossibleAugmentLevel}
						onChange={updateMaxAugmentLevel}
						width='120px'
						marginY='10px'
						{...getAugmentNameColor(maxAugmentLevel, false)}
					>
						<SliderTrack>
							<SliderFilledTrack background='currentcolor' />
						</SliderTrack>
						<SliderThumb background='currentcolor' _focusVisible={{}} />
					</Slider>
					<Text fontSize='xl' margin='0' width='80px' textAlign='center'>
						{maxAugmentLevel === 0 ? 'Unlimited' : maxAugmentLevel}
					</Text>
				</Flex>
			</Flex>
			<Heading size={'md'} textAlign='center'>
				Sorting Options
			</Heading>
			<Flex justifyContent='center' alignItems='center'>
				{toggleButton(
					sortSuccessfulToStart,
					toggleSortSuccessfulToStart,
					<BsArrow90DegDown />,
					<BsArrow90DegDown style={{ transform: 'scaleX(-1)' }} />,
					`Sort to ${sortSuccessfulToStart ? 'Start' : 'End'}`,
					'blue'
				)}
				<IdlescapeWrappingTooltip
					content='Sets where successful augmentations are placed in the queue.'
					activateOnClick
				>
					<Text fontSize='20px' cursor='pointer' margin='0'>
						<AiOutlineInfoCircle />
					</Text>
				</IdlescapeWrappingTooltip>
			</Flex>
		</Box>
	);
}
