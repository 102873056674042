import React from 'react';
import './Cooking.css';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Cooking from './Cooking';
import Preparation from './Preparation';
import { cookingRecipeAtom } from '../../../../atoms/cookingRecipeAtom';
import { cookingTabAtom } from '../../../../atoms/cookingTabAtom';
import { Box, Flex } from '@chakra-ui/react';
import PreparationList from './PreparationList';

export default function CookingContainer() {
	const [tab, setTab] = useRecoilState(cookingTabAtom);
	const setRecipe = useSetRecoilState(cookingRecipeAtom);

	return (
		<div className='cooking-container'>
			<div className='cooking-tabs'>
				<div
					onClick={() => setTab('preparation')}
					className={tab === 'preparation' ? 'cooking-tab-selected' : ''}
				>
					<img src='/images/cooking/raw_chicken.png' alt='Preparation logo' className='enchanting-tab-icon' />
					<span>Preparing</span>
				</div>
				<div
					onClick={() => {
						if (tab === 'cooking') return;
						setTab('cooking');
						setRecipe(0);
					}}
					className={tab === 'cooking' ? 'cooking-tab-selected' : ''}
				>
					<img src='/images/cooking/cooking_icon.png' alt='Cooking logo' className='enchanting-tab-icon' />
					<span>Cooking</span>
				</div>
				<div
					onClick={() => {
						if (tab === 'alchemy') return;
						setTab('alchemy');
						setRecipe(0);
					}}
					className={tab === 'alchemy' ? 'cooking-tab-selected' : ''}
				>
					<img
						src='/images/cooking/alchemy/rainbow_pot.png'
						alt='Alchemy logo'
						className='enchanting-tab-icon'
					/>
					<span>Brewing (Alchemy)</span>
				</div>
			</div>
			{tab === 'preparation' && (
				<Box height='calc(100% - 20px)'>
					<Flex height='100%' flexWrap='wrap'>
						<Preparation />
						<PreparationList />
					</Flex>
				</Box>
			)}
			{tab === 'cooking' && <Cooking />}
			{tab === 'alchemy' && <Cooking isAlchemy={true} />}
		</div>
	);
}
