import { Box, Heading } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import React, { useEffect, useRef } from 'react';
import { TSkillTab } from '../../../../../game-server/src/Atypes/Skills';
import { IItemData } from '../../../../../game-server/src/modules/items/items.interface';
import { ILoot } from '../../../../../game-server/src/modules/items/Loot.interface';
import { ILocationData, ILocationNode } from '../../../../../game-server/src/modules/locations/location.interface';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { MINIMUM_ACTION_TIME_IN_SECONDS } from '../../../utils/constantsCollection';
import {
	getFishingGatherChance,
	getFishingGatherLength,
	getFishingNodeLoot,
	getFishingNodes,
	getFishingSearchChance,
	getFishingSearchLength,
	getNodeSize,
} from '../../../utils/fishingFunctions';
import { itemList } from '../../../utils/itemList';
import { enchantmentsIds } from '../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { locationsIds } from '../../../utils/lookup-dictionaries/lookupLocationList';
import { locationsLootTable } from '../../../utils/lootTableLocations';
import { formatNumber } from '../../../helper/StringFormatter';
import { IProgressBarRef, ProgressBar } from '../../layout/UI/ProgressBar';
import ActionQueueButton from '../ActionQueue/ActionQueueButton';
import { CraftingAugmentingData } from '../CraftingAugmenting/CraftingAugmentingData';
import ResourceList from '../ResourceList';
import { IFishingData } from './Fishing/FishingData';
import './GatheringResource.css';

export default function GatheringResource(props: { location: ILocationData; fishingData?: IFishingData }) {
	const progressBarRef = useRef<IProgressBarRef>(null);

	const actionQueue = usePlayerField('actionQueue');
	const skills = usePlayerField('skills');
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');
	const skill = props.location.actionType.replace('Action-', '').toLowerCase() as TSkillTab;
	const effectiveLevel = skills[skill].level + skills[skill].masteryLevel + skillEquipmentStats[skill];
	const affixes = usePlayerField('affixes');
	const settings = usePlayerField('settings');
	const highPerformance = settings.miscellaneous.highPerformance;
	const running =
		actionQueue?.location === props.location.locID && actionQueue.actionType === props.location.actionType;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, skill);
	const fishingStrength = usePlayerEnchantmentStrength(enchantmentsIds.fishing, skill);
	const scholarStrength = usePlayerEnchantmentStrength(enchantmentsIds.scholar, skill);
	const wealthStrength = usePlayerEnchantmentStrength(enchantmentsIds.wealth, skill);
	const spiralingOutOfControlStrength = usePlayerEnchantmentStrength(enchantmentsIds.spiraling_out_of_control, skill);
	const hamsterHunterStrength = usePlayerEnchantmentStrength(enchantmentsIds.hamster_hunter, skill);

	const superHeatingStrength = usePlayerEnchantmentStrength(enchantmentsIds.superheating, skill);
	const superCoolingStrength = usePlayerEnchantmentStrength(enchantmentsIds.supercooling, skill);

	const natureStrength = usePlayerEnchantmentStrength(enchantmentsIds.nature, skill);
	const herbalistStrength = usePlayerEnchantmentStrength(enchantmentsIds.herbalist, skill);
	const seedHarvestingStrength = usePlayerEnchantmentStrength(enchantmentsIds.seed_harvesting, skill);

	const fishingMagnetismStrength = usePlayerEnchantmentStrength(enchantmentsIds.fishing_magnetism, skill);
	const deadliestCatchStrength = usePlayerEnchantmentStrength(enchantmentsIds.deadliest_catch, skill);
	const fiberFinderStrength = usePlayerEnchantmentStrength(enchantmentsIds.fiber_finder, skill);
	const fishData = props.fishingData;

	const overallTime = getTime();

	useEffect(() => {
		socket.on('animation:start', startAnimation);

		return () => {
			socket.off('animation:start');
		};
	}, []);

	useEffect(() => {
		if (!running) {
			progressBarRef.current?.stopAnimation();
		} else {
			const expectedEndTime = actionQueue?.info?.completionTime;
			let newLength = 0;
			if (expectedEndTime) {
				newLength = expectedEndTime - new Date().getTime();
			}
			progressBarRef.current?.startAnimation(newLength);
		}
	}, [running]);

	function startWorking() {
		const locID = props.location.locID;
		if (running) {
			socket.emit('action:stop');
		} else {
			socket.emit('action:start', {
				action: skill,
				location: locID,
			});
		}
	}

	function queueButton() {
		const locID = props.location.locID;
		return <ActionQueueButton action={skill} location={locID} className='resource-button' />;
	}

	function getLocationNodeFrequencies(baseLocation: ILocationData, nodes: ILocationNode[] | undefined) {
		if (!nodes) return [];
		nodes = structuredClone(nodes);
		if (fishData) {
			const nodeBonusSize = affixes.find((a) => a.path === 'fishing.node_bonus_size')?.value ?? 0;
			const fishingNodes = getFishingNodes(
				baseLocation,
				fishData.bonus,
				fishData.level,
				fishingMagnetismStrength
			);
			for (let i = nodes.length - 1; i >= 0; i--) {
				const fishNode = fishingNodes.find((fish) => fish.id === i);
				const node = nodes[i];
				if (fishNode) {
					const nodeSize = getNodeSize(node, fishData.level, fishData.bait, nodeBonusSize);
					node.frequency = fishNode.frequency ?? 0;
					node.minimumBaseAmount = nodeSize.minAmount;
					node.maximumBaseAmount = nodeSize.maxAmount;
				} else {
					// nodes.splice(i, 1);
				}
			}
		} else if (skill === 'foraging') {
			for (const node of nodes) {
				// Multiply by strength
				if (node.tags?.includes('tree')) {
					node.frequency += natureStrength;
				}
				if (node.tags?.includes('plants')) {
					node.frequency += herbalistStrength;
				}
				if (node.tags?.includes('seeds')) {
					node.frequency += seedHarvestingStrength;
				}
				node.frequency = Math.min(node.frequency, node?.maxFrequency ?? 999);
			}
		}

		const itemFrequencies: {
			[id: number]: { frequency: number; minAmount: number; maxAmount: number; minRarity: number };
		} = {};
		for (const node of nodes) {
			const frequencyMultiplier = Math.max(
				0,
				node.frequency * (node.minimumBaseAmount + (node.maximumBaseAmount ?? node.minimumBaseAmount))
			);
			let nodeLoot = locationsLootTable[baseLocation.locID].nodes?.[node.nodeID];
			if (!nodeLoot) continue;
			if (fishData && frequencyMultiplier) {
				nodeLoot = structuredClone(nodeLoot);
				const fishLoot = getFishingNodeLoot(
					locationsLootTable[baseLocation.locID],
					node.nodeID,
					fishData.bonus,
					fishData.level,
					deadliestCatchStrength,
					fiberFinderStrength,
					itemList
				);
				for (const loot of nodeLoot) {
					const found = fishLoot.find((l) => l.id === loot.id);
					if (found) {
						loot.frequency = found.frequency;
					}
				}
			}
			for (const loot of nodeLoot) {
				const lootFrequency = Math.max(0, loot.frequency ?? 0) * frequencyMultiplier;
				if (itemFrequencies[loot.id]) {
					itemFrequencies[loot.id].frequency += lootFrequency;
					itemFrequencies[loot.id].minAmount = Math.min(
						itemFrequencies[loot.id].minAmount,
						loot.minAmount ?? 1
					);
					itemFrequencies[loot.id].maxAmount = Math.max(
						itemFrequencies[loot.id].maxAmount,
						loot.maxAmount ?? 1
					);
					itemFrequencies[loot.id].minRarity = Math.max(
						itemFrequencies[loot.id].minRarity,
						Math.min(loot.frequency ?? 0, node.frequency)
					);
				} else {
					itemFrequencies[loot.id] = {
						frequency: lootFrequency,
						minAmount: loot.minAmount ?? 1,
						maxAmount: loot.maxAmount ?? 1,
						minRarity: Math.min(loot.frequency ?? 0, node.frequency),
					};
				}
			}
		}

		const totalFrequency = Object.values(itemFrequencies).reduce((acc, b) => acc + b.frequency, 0);

		const sortable: { id: number; frequency: number; minAmount: number; maxAmount: number; minRarity: number }[] =
			[];
		for (const key in itemFrequencies) {
			itemFrequencies[key].frequency = itemFrequencies[key].frequency / totalFrequency;
			sortable.push({ id: Number(key), ...itemFrequencies[key] });
		}

		// TODO we can apply modifications here based on skill/enchantments/etc

		return sortable;
	}

	function startAnimation(data: { action: string; location: number; length: number; resource?: number }) {
		if (props.location.locID === data.location && props.location.actionType === data.action) {
			progressBarRef.current?.startAnimation(data.length * 1000);
		}
	}

	function getTime() {
		let time = props.location.baseDuration / 1000;
		time = time / (1 + hasteStrength);

		if (props.location.actionType) {
			if (props.location.actionType.includes('Foraging') || props.location.actionType.includes('Mining')) {
				time /= 99 + effectiveLevel;
				time *= 100;
			}
			if (spiralingOutOfControlStrength && props.location.locID === locationsIds.slate_spires) {
				time /= 1 + spiralingOutOfControlStrength * skills.runecrafting.masteryLevel;
			}
			if (hamsterHunterStrength && props.location.locID === locationsIds.underground_mine) {
				time /= 1 + hamsterHunterStrength;
			}
		}
		return Math.max(MINIMUM_ACTION_TIME_IN_SECONDS, time);
	}

	// Calculate experience, value, and loot
	let totalFrequency = 0;

	let actionsPerHour = Math.floor(3600 / overallTime);
	const locationLoot = locationsLootTable[props.location.locID];
	if (locationLoot.loot) {
		for (const lootItem of locationLoot.loot) {
			totalFrequency += lootItem.frequency ?? 0;
		}
	}

	// calculate required resource list
	const requiredResources: React.ReactElement[] = [];

	//level
	let skillItem: IItemData = itemList[itemsIds.bronze_pickaxe];
	if (props.location.accessRequirements?.requiredSkills) {
		for (const requiredSkill of props.location.accessRequirements.requiredSkills) {
			if (requiredSkill) {
				const reqSkill = requiredSkill.skill;
				const reqLevel = requiredSkill.level;
				if (reqSkill === 'mining') {
					if (reqLevel >= 60) {
						skillItem = itemList[itemsIds.stygian_pickaxe];
					} else if (reqLevel >= 50) {
						skillItem = itemList[itemsIds.runite_pickaxe];
					} else if (reqLevel >= 40) {
						skillItem = itemList[itemsIds.adamantite_pickaxe];
					} else if (reqLevel >= 30) {
						skillItem = itemList[itemsIds.mithril_pickaxe];
					} else if (reqLevel >= 10) {
						skillItem = itemList[itemsIds.iron_pickaxe];
					} else {
						skillItem = itemList[itemsIds.bronze_pickaxe];
					}
				}
				if (reqSkill === 'foraging') {
					if (reqLevel >= 60) {
						skillItem = itemList[itemsIds.stygian_hatchet];
					} else if (reqLevel >= 50) {
						skillItem = itemList[itemsIds.runite_hatchet];
					} else if (reqLevel >= 40) {
						skillItem = itemList[itemsIds.adamantite_hatchet];
					} else if (reqLevel >= 30) {
						skillItem = itemList[itemsIds.mithril_hatchet];
					} else if (reqLevel >= 10) {
						skillItem = itemList[itemsIds.iron_hatchet];
					} else {
						skillItem = itemList[itemsIds.bronze_hatchet];
					}
				}
				if (reqSkill === 'fishing') {
					if (reqLevel >= 85) {
						skillItem = itemList[itemsIds.supreme_tacklebox];
					} else if (reqLevel >= 65) {
						skillItem = itemList[itemsIds.pro_tacklebox];
					} else if (reqLevel >= 50) {
						skillItem = itemList[itemsIds.advanced_tacklebox];
					} else if (reqLevel >= 20) {
						skillItem = itemList[itemsIds.novice_tacklebox];
					} else {
						skillItem = itemList[itemsIds.basic_tacklebox];
					}
				}
				requiredResources.push(
					<IdlescapeWrappingTooltip content='Level Required'>
						<div className='resource-node-time-tooltip'>
							<img src={skillItem.itemImage} alt='level' className='icon16' />
							<span>{reqLevel}</span>
						</div>
					</IdlescapeWrappingTooltip>
				);
			}
		}
	}
	const requiredLevel = props.location?.accessRequirements?.requiredSkills?.[0].level ?? 0;
	//time
	if (!fishData) {
		requiredResources.push(
			<IdlescapeWrappingTooltip content='Time required'>
				<div className='resource-node-time-tooltip'>
					<img src='/images/clock.png' alt='time' className='icon16' />
					<span>{formatNumber(overallTime)}s</span>
				</div>
			</IdlescapeWrappingTooltip>
		);
	} else {
		const searchTime = getFishingSearchLength(props.location, 1 + hasteStrength, fishData.bait, 0);
		const gatherTime = getFishingGatherLength(props.location, 1 + hasteStrength, fishData.reel);
		actionsPerHour = Math.floor(3600 / (gatherTime / 1000));

		requiredResources.push(
			<IdlescapeWrappingTooltip content='Searching Time | Gathering Time'>
				<div className='resource-node-time-tooltip'>
					<img src='/images/clock.png' alt='time' className='icon16' />
					<span>
						~{formatNumber(searchTime / 1000)}s | ~{formatNumber(gatherTime / 1000)}s
					</span>
				</div>
			</IdlescapeWrappingTooltip>
		);

		const fishingSuccessBonus = affixes.find((a) => a.path === 'fishing.success_chance')?.value ?? 0;

		let findChance = getFishingSearchChance(
			fishingStrength,
			fishData.levelLoc[props.location.locID],
			fishData.bait,
			fishingSuccessBonus,
			1
		);
		findChance = Math.min(1, findChance) * 100;

		let gatherChance = getFishingGatherChance(
			fishingStrength,
			fishData.levelLoc[props.location.locID],
			fishData.reel,
			1,
			1,
			fishingSuccessBonus
		);
		gatherChance = Math.min(1, gatherChance) * 100;

		requiredResources.push(
			<IdlescapeWrappingTooltip
				content={
					<>
						<span>Base Find Chance | Base Gather Chance</span>
						<br />
						<span>Will generally be higher in practice</span>
					</>
				}
			>
				<div className='resource-node-time-tooltip'>
					<img src='/images/fishing/chance_icon.png' alt='chance' className='icon16' />
					<span>
						~{formatNumber(findChance)}% | ~{formatNumber(gatherChance)}%
					</span>
				</div>
			</IdlescapeWrappingTooltip>
		);
	}

	// Experience
	if (props.location.xpPerCompletion && props.location.xpPerCompletion.length && props.location.actionType) {
		let xp = 0;
		const found = props.location.xpPerCompletion.find((e) =>
			props.location.actionType.toLowerCase().includes(e.skill)
		);
		if (found) {
			xp = found.amount;
		} // Wealth overridees Scholar
		if (scholarStrength && !wealthStrength) {
			xp *= 1 + scholarStrength;
		}
		xp = Math.floor(xp);
		if (xp > 0) {
			requiredResources.push(
				<IdlescapeWrappingTooltip content='Experience Per Action'>
					<div className='resource-node-time-tooltip'>
						<img src='/images/total_level.png' alt='total_level' className='icon16' />
						<span>{xp}</span>
					</div>
				</IdlescapeWrappingTooltip>
			);
		}
	}

	// generate tooltip
	let resources: {
		id: number;
		frequency: number;
		minAmount?: number;
		maxAmount?: number;
		minRarity?: number;
	}[] = [];
	if (locationLoot.nodes !== undefined) {
		resources = getLocationNodeFrequencies(props.location, props.location.nodes);
		resources.sort((a, b) => {
			if (b.frequency !== a.frequency) {
				return b.frequency - a.frequency;
			}
			return (b.minRarity ?? 0) - (a.minRarity ?? 0);
		});
	} else if (locationLoot.loot !== undefined) {
		resources = miningLoot(locationLoot.loot).map((loot) => {
			const frequency = (loot.frequency ?? 0) / totalFrequency;
			return {
				id: loot.id,
				frequency: frequency,
				minAmount: loot.minAmount,
				maxAmount: loot.maxAmount,
			};
		});
	}

	function miningLoot(locationLoot: ILoot[]) {
		if (!superHeatingStrength && !superCoolingStrength) {
			return locationLoot;
		}
		const temperaturePower = superHeatingStrength - superCoolingStrength;
		totalFrequency = 0;
		return locationLoot.map((loot) => {
			let frequency = loot.frequency ?? 0;
			const heatValue = barHeatFromOre(loot.id);
			if (heatValue > 0) {
				const heatTier = 1 + heatValue ** (1 / 3) / 10;
				const temperatureEffect = heatTier ** (50 * temperaturePower);
				frequency *= temperatureEffect;
			}
			totalFrequency += frequency;
			return {
				id: loot.id,
				frequency: frequency,
				minAmount: loot.minAmount,
				maxAmount: loot.maxAmount,
			};
		});
	}

	function barHeatFromOre(itemId: number) {
		const craftingList = CraftingAugmentingData.getCraftingAugmentingList();
		for (const recipe of Object.values(craftingList)) {
			if (!recipe.smithing) continue;
			if (Object.keys(recipe.smithing).includes(String(itemId))) {
				return recipe.smithing[itemsIds.heat] ?? 0;
			}
		}
		return 0;
	}

	// Clear resources if Scholar or Wealth is active
	if (wealthStrength) {
		let gold = 0;
		for (const item of resources) {
			const itemData = itemList[item.id];
			const value = itemData.value ?? 0;
			const minAmount = item.minAmount ?? item.maxAmount ?? 0;
			const maxAmount = item.maxAmount ?? minAmount;
			gold += value * item.frequency * ((minAmount + maxAmount) / 2) * (1 + wealthStrength);
		}
		gold = Math.floor(gold);
		resources.splice(0, resources.length);
		resources.push({ id: itemsIds.gold, frequency: 1, minAmount: gold, maxAmount: gold });
	} else if (scholarStrength) {
		resources.splice(0, resources.length);
	}

	const tooltipBody = (
		<div className='resource-container-tooltip'>
			{props.location.extraTooltipInfo !== undefined && props.location.extraTooltipInfo !== 'WIP' ? (
				<>
					<p className={'red-text'}>{props.location.extraTooltipInfo}</p>
					<hr></hr>
				</>
			) : null}

			<div className='resource-container-loot-title'>Loot</div>
			<div className='resource-container-resource-list'>
				<ResourceList resources={resources} />
			</div>
			{scholarStrength && !wealthStrength ? 'Scholar destroys loot!' : ''}
			<div className='resource-container-resource-aph'>Actions Per Hour: ~{actionsPerHour}</div>
		</div>
	);

	return (
		<Box className='resource-container' backgroundImage={props.location.locationImage}>
			{requiredLevel > skills[skill].level && (
				<div className='locked-cover'>
					<div>
						Recommended Tool: <img src={skillItem.itemImage} className='icon60' alt={skillItem.name} />
						<hr></hr>
						Required Level: {requiredLevel}
					</div>
				</div>
			)}
			<Heading
				as={'h3'}
				size='md'
				textAlign={'center'}
				textShadow={'0 0 5px black'}
				backgroundColor={'#0000005e'}
				marginTop={'0'}
				padding={'10px 0'}
				backdropFilter={'blur(3px)'}
				borderTopRadius={'10px'}
			>
				{props.location.name}
			</Heading>
			<IdlescapeWrappingTooltip activateOnClick content={tooltipBody}>
				<div className='resource-node-tooltip-container'>
					<InfoOutlineIcon id='resource-node-tooltip' />
				</div>
			</IdlescapeWrappingTooltip>
			<img
				className='resource-container-image'
				src={'/images/transparent-do-not-delete.png'}
				alt={props.location.name}
			/>
			{!highPerformance && (
				<ProgressBar
					className='resource-container-progress'
					ref={progressBarRef}
					value={0}
					max={100}
					theme='default'
					gridArea='bar'
				/>
			)}

			<div className='resource-required-resources'>
				{requiredResources.map((requiredResource, index) => (
					<div key={props.location.locID + 'requiredResource' + index} className='resource-property'>
						{requiredResource}
					</div>
				))}
			</div>

			<div className='resource-container-button'>
				<button
					className='resource-button'
					disabled={requiredLevel > skills[skill].level}
					onClick={startWorking}
				>
					{actionQueue && props.location.locID === actionQueue.location
						? 'Stop'
						: props.location.actionVerb ?? props.location.actionType.replace('Action-', '')}
				</button>
				{queueButton()}
			</div>
		</Box>
	);
}
