// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TSkillTab } from '../../../game-server/src/Atypes/Skills';
import { TTrueInventoryType } from '../../../game-server/src/modules/items/Inventory.interface';
import { ILoadout } from '../../../game-server/src/modules/loadout/loadout.interface';
import { TEquipmentSlots } from '../../../game-server/src/modules/player/PlayerTypes';
import { abilitiesIds } from '../utils/lookup-dictionaries/lookupAbilityList';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const GENERAL_CHAT_ID = 1;

export const PLATINUM_PER_MONTH_FROM_PLUS = 600;

export const SHRINE_MINIMUM_DONATION_VALUE = 1000;
export const PERSONAL_CONTRIBUTION_THRESHOLDS = [400_000, 8_000_000, 100_000_000, Infinity];
export const SHRINE_BASE_TIME = 0;
export const SHRINE_TIME_PER_LEVEL = 1000 * 60 * 60 * 8;
export const MATERIAL_VENDOR_COST_MULTIPLIER = 15;

//Platinum buff
export const PLATINUM_PER_MINUTE = 10;
export const MINIMUM_BUFF_MINUTES = 10;
export const VALID_PLATINUM_BUFFS = [
	// enchantmentsIds.loot_goblin,
	enchantmentsIds.intuition,
	enchantmentsIds.empowered_prospecting,
	enchantmentsIds.empowered_gathering,
	// enchantmentsIds.archaeology,
	// enchantmentsIds.bait_preserve,
	// enchantmentsIds.pungent_bait,
	// enchantmentsIds.reinforced_line,
	// enchantmentsIds.oil_spill,
	enchantmentsIds.fertilizing,
	enchantmentsIds.cultivation,
	// enchantmentsIds.gold_digger,
	enchantmentsIds.farming,
	// enchantmentsIds.root_digging,
	enchantmentsIds.empowered_root_digging,
	enchantmentsIds.inferno,
	enchantmentsIds.treasure_hunter,
	enchantmentsIds.enlightenment,
	enchantmentsIds.shrimp_lord,
	enchantmentsIds.runecrafting,
	// enchantmentsIds.refining,
	// enchantmentsIds.crafting,
	enchantmentsIds.cooking,
	// enchantmentsIds.fishing,
	enchantmentsIds.efficiency,
	// enchantmentsIds.naturalist,
	enchantmentsIds.haste,
	// enchantmentsIds.pyromancy,
	// enchantmentsIds.prospector,
	// enchantmentsIds.embers,
	enchantmentsIds.researchers_boon,
	enchantmentsIds.adventurers_boon,
];

export const MAXIMUM_MARKET_PRICE = 10 ** 11; // 100 billion
export const MINIMUM_ACTION_TIME_IN_MILLISECONDS = 333;
export const MINIMUM_ACTION_TIME_IN_SECONDS = MINIMUM_ACTION_TIME_IN_MILLISECONDS / 1000;
export const MAXIMUM_DURATION_MULTIPLIER = 5;
export const MARKET_HISTORY_COOLDOWN = 500;
export const MULTI_MOVE_COOLDOWN = 10000;
export const AFFIX_COSTS = {
	legendary: 400,
	epic: 200,
	rare: 100,
	uncommon: 50,
	common: 25,
	junk: 0,
};
export const RANDOM_AFFIX_COST = 10;
export const AFFIX_REDUCED_EFFECT_MULTIPLIER = 0.5;
export const AFFIX_2HANDED_EFFECT_MULTIPLIER = 1.5;
export const AFFIX_SLOTS_PER_RARITY = {
	legendary: 3,
	epic: 3,
	rare: 2,
	uncommon: 2,
	common: 1,
	junk: 0,
};
export const AFFIX_GOLD_BASE_COST = 10000;
export const AFFIX_GOLD_PER_MULT_RARITY = {
	legendary: 2,
	epic: 1.75,
	rare: 1.5,
	uncommon: 1.25,
	common: 1,
	junk: 0.5,
};
export const AFFIX_DUST_PER_RARITY = {
	legendary: itemsIds.mythical_runic_dust,
	epic: itemsIds.prime_runic_dust,
	rare: itemsIds.singular_runic_dust,
	uncommon: itemsIds.unusual_runic_dust,
	common: itemsIds.basic_runic_dust,
	junk: itemsIds.basic_runic_dust,
};
export const AFFIX_DUST_PER_ITEM_TIER = [
	itemsIds.basic_runic_dust,
	itemsIds.basic_runic_dust,
	itemsIds.basic_runic_dust,
	itemsIds.unusual_runic_dust,
	itemsIds.singular_runic_dust,
	itemsIds.prime_runic_dust,
	itemsIds.mythical_runic_dust,
	itemsIds.mythical_runic_dust,
	itemsIds.mythical_runic_dust,
];
export const AFFIX_EXP_PER_MULT_RARITY = {
	legendary: 10,
	epic: 7,
	rare: 5,
	uncommon: 3,
	common: 1,
	junk: 0.5,
};
export const AFFIX_BLACKLISTED_SLOTS: TEquipmentSlots[] = []; // nothing :)
export const AFFIX_COMBAT_SLOTS: TEquipmentSlots[] = ['weapon', 'shield', 'helm', 'body', 'legs', 'gloves', 'boots'];
export const AFFIX_TOOL_SLOTS: TEquipmentSlots[] = [
	'pickaxe',
	'hatchet',
	'hoe',
	'tacklebox',
	'tongs',
	'tome',
	'ladle',
	'chisel',
];
export const AFFIX_JEWELRY_SLOTS: TEquipmentSlots[] = ['ring', 'necklace', 'arrows', 'cape'];
export const AFFIX_GEAR_SCRAP_PER_RARITY = {
	legendary: itemsIds.legendary_gear_scraps,
	epic: itemsIds.epic_gear_scraps,
	rare: itemsIds.rare_gear_scraps,
	uncommon: itemsIds.uncommon_gear_scraps,
	common: itemsIds.common_gear_scraps,
	junk: itemsIds.common_gear_scraps,
};
// key is the rarity of the item, value is the maximum rarity of the affix
// these are global maxes and override the affix specific tier checks
export const AFFIX_MAX_RARITY_BY_ITEM_RARITY = {
	legendary: 'legendary',
	epic: 'legendary',
	rare: 'legendary',
	uncommon: 'rare',
	common: 'rare',
	junk: 0,
};

export const BASE_ACTIONS_COUNT = 1;
export const PLUS_ACTIONS_COUNT = 3; // how many more actions a plus member gets
// export const BASE_ACTIONS_LENGTH = 10; // 10 minutes or actions
// export const PLUS_ACTIONS_LENGTH = 3 * 60; // 3 hours or 180 actions
// export const BONUS_ACTIONS_LENGTH = 30; // 30 minutes or actions
export const BASE_PROFILE_SLOTS = 4;
export const PLUS_PROFILE_SLOTS = 4;
export const BASE_MAX_CHARACTERS = 3;
export const PLUS_MAX_CHARACTERS = 5;
export const BASE_ACTIVE_CHARACTERS = 2;
export const PLUS_ACTIVE_CHARACTERS = 1;

export const GATHERING_SKILLS = ['mining', 'foraging', 'fishing'];
export const PRODUCTION_SKILLS = ['enchanting', 'runecrafting', 'smithing', 'cooking'];

export const LEGENDARY_TOOL_IDS = [
	itemsIds.dwarven_rocksmasher,
	itemsIds.oceans_embrace,
	itemsIds.the_grovekeeper,
	itemsIds.ancient_runic_chisel,
	itemsIds.elvish_hoe,
	itemsIds.golden_spoon,
	itemsIds.dwarven_tongs,
];

// Enhance on positive means it increases
// Enhance on negative means it decreases
// Maintain on positive means it decreases
// Maintain on negative means it increases
// Positive and negative is >1 and <1 respectively
export const FORGE_FIELDS_TO_ENHANCE_OR_MAINTAIN = {
	forgeHeatCost: {
		enhanceOnPositive: false,
		maintainOnPositive: true,
		enhanceOnNegative: true,
		maintainOnNegative: false,
		skipField: false,
		neutral: 1,
	},
	forgeMaterialCost: {
		enhanceOnPositive: false,
		maintainOnPositive: true,
		enhanceOnNegative: true,
		maintainOnNegative: false,
		skipField: false,
		neutral: 1,
	},
	forgeSpeedMult: {
		enhanceOnPositive: false,
		maintainOnPositive: true,
		enhanceOnNegative: true,
		maintainOnNegative: false,
		skipField: false,
		neutral: 1,
	},
	forgeBonusBars: {
		enhanceOnPositive: true,
		maintainOnPositive: false,
		enhanceOnNegative: false,
		maintainOnNegative: true,
		skipField: false,
		neutral: 0,
	},
	refiningStrengthMult: {
		enhanceOnPositive: true,
		maintainOnPositive: false,
		enhanceOnNegative: false,
		maintainOnNegative: true,
		skipField: false,
		neutral: 1,
	},
	forgeIntensityHeatCostMult: {
		enhanceOnPositive: false,
		maintainOnPositive: false,
		enhanceOnNegative: false,
		maintainOnNegative: false,
		skipField: true,
		neutral: 1,
	},
	forgeIntensityMaterialCostMult: {
		enhanceOnPositive: false,
		maintainOnPositive: false,
		enhanceOnNegative: false,
		maintainOnNegative: false,
		skipField: true,
		neutral: 1,
	},
	forgeIntensitySpeedMult: {
		enhanceOnPositive: false,
		maintainOnPositive: false,
		enhanceOnNegative: false,
		maintainOnNegative: false,
		skipField: true,
		neutral: 1,
	},
	forgeIntensityBonusBars: {
		enhanceOnPositive: false,
		maintainOnPositive: false,
		enhanceOnNegative: false,
		maintainOnNegative: false,
		skipField: true,
		neutral: 1,
	},
};

export const FORGE_REFINING_BASE_TIME_ADJUSTMENT = 5000;
export const FORGE_REFINING_PROBABILITY_ADJUSTMENT = 24000;

export const INFERNO_CAP = 5; // Hard cap at 5 inferno procs, always
export const FOOD_DEFAULT_ENCHANT_STRENGTH = 2;

export const COOKING_BREAKPOINT = 40;
export const ALCHEMY_BREAKPOINT = 5;
export const COOKING_HEAT_PER_SIZE = 3;
export const COOKING_EXP_PER_DIFFICULTY = 5;
export const COOKING_HEAT_PER_ESSENCE = 15;
export const FISHOIL_PER_SIZE = 0.05;
// Multiplies the time and exp of cooking per ingredient type, the first value default should not be hit
export const COOKING_TIME_EXP_MULTI = [2, 1.5, 1.75, 2];

export const VIAL_IDS = [itemsIds.empty_vial, itemsIds.empty_large_vial, itemsIds.empty_reinforced_vial];
export const NORMAL_POTION_IDS = [
	itemsIds.empty_vial,
	itemsIds.common_potion,
	itemsIds.rare_potion,
	itemsIds.epic_potion,
	itemsIds.legendary_potion,
];
export const LARGE_POTION_IDS = [
	itemsIds.empty_large_vial,
	itemsIds.common_large_potion,
	itemsIds.rare_large_potion,
	itemsIds.epic_large_potion,
	itemsIds.legendary_large_potion,
];
export const CONCENTRATED_POTION_IDS = [
	itemsIds.empty_reinforced_vial,
	itemsIds.common_concentrated_potion,
	itemsIds.rare_concentrated_potion,
	itemsIds.epic_concentrated_potion,
];

export const FOOD_INITIAL_COST = [1, 6, 5, 4];
export const FOOD_QUALITY_SLIDER = [1, 3, 1.5, 1];
export const POTION_DEFAULT_AMOUNT = 50;
export const POTION_UPGRADE_COST = [0, 1, 2, 4, 8];

export const ALCHEMY_TAGS = [
	itemsIds.spicy_essence,
	itemsIds.sweet_essence,
	itemsIds.sour_essence,
	itemsIds.bitter_essence,
];

export const SKILLING_MASTER_ENCHANTS = [
	enchantmentsIds.master_miner,
	enchantmentsIds.master_forager,
	enchantmentsIds.master_fisherman,
	enchantmentsIds.master_chef_set,
	enchantmentsIds.master_smith,
	enchantmentsIds.master_runecrafter,
];

export const SKILLING_LEGENDARY_ENCHANTS = [
	enchantmentsIds.legendary_rockbreaker,
	enchantmentsIds.legendary_angler,
	enchantmentsIds.legendary_lumberjack,
	enchantmentsIds.legendary_chef,
	enchantmentsIds.legendary_smith,
	enchantmentsIds.legendary_runecrafter,
];

export const COMBAT_INVENTORY_SIZE = 28;
export const SORTED_INVENTORIES: TTrueInventoryType[] = ['augmentingItemSlot', 'tacklebox', 'scrapQueue'];

export const MERC_FOOD_COEFFICIENT = 0.5;
export const MERC_RESPAWN_TIME = 60000; //ms

export const BASE_DIFFICULTY = 10;
export const STARTING_DIFFICULTY_MULT = 1 / 2;

export const THREAT_PROFILES = {
	0: {
		name: 'Default',
		damageThreat: 2,
		levelThreat: 3,
		weaponThreat: 3,
		armorThreat: 2,
		attackSpeedThreat: 3,
		hpThreat: 2,
	},
	// Prioritize Low HP
	1: {
		name: 'Focus Low HP',
		damageThreat: 2,
		levelThreat: 3,
		weaponThreat: 3,
		armorThreat: 2,
		attackSpeedThreat: 3,
		hpThreat: 99,
	},
	// Prioritize High HP
	2: {
		name: 'Focus High HP',
		damageThreat: 8,
		levelThreat: 12,
		weaponThreat: 12,
		armorThreat: 8,
		attackSpeedThreat: 12,
		hpThreat: 1,
	},
	// Prioritize Damage Threat
	3: {
		name: 'Focus DPS',
		damageThreat: 10,
		levelThreat: 1,
		weaponThreat: 10,
		armorThreat: 1,
		attackSpeedThreat: 1,
		hpThreat: 1,
	},
	// Prioritize Tanks
	4: {
		name: 'Focus Tanks',
		damageThreat: 1,
		levelThreat: 1,
		weaponThreat: 1,
		armorThreat: 99,
		attackSpeedThreat: 1,
		hpThreat: 1,
	},
};

export const DO_NOT_RENAME = [itemsIds.book, itemsIds.task];

export const RUNECRAFTING_ESSENCE = [
	itemsIds.air_essence,
	itemsIds.earth_essence,
	itemsIds.fire_essence,
	itemsIds.water_essence,
	itemsIds.blood_essence,
	itemsIds.death_essence,
	itemsIds.chaos_essence,
	itemsIds.nature_essence,
	itemsIds.mind_essence,
	itemsIds.cosmic_essence,
];

export const RUNECRAFTING_RUNES = [
	itemsIds.air_rune,
	itemsIds.earth_rune,
	itemsIds.fire_rune,
	itemsIds.water_rune,
	itemsIds.blood_rune,
	itemsIds.death_rune,
	itemsIds.chaos_rune,
	itemsIds.nature_rune,
	itemsIds.mind_rune,
	itemsIds.cosmic_rune,
];

export const CLOTHWEAVING_FIBERS = [
	itemsIds.aqueous_grass,
	itemsIds.aqueous_grass, // Dupe for Restored Cloth
	itemsIds.water_weeds,
	itemsIds.river_vines,
	itemsIds.violet_ribbons,
	itemsIds.white_reeds,
	itemsIds.ancient_kelp,
];

export const CLOTHWEAVING_CLOTH = [
	itemsIds.rough_cloth,
	itemsIds.restored_cloth,
	itemsIds.lesser_cloth,
	itemsIds.imbued_cloth,
	itemsIds.major_cloth,
	itemsIds.greater_cloth,
	itemsIds.elder_cloth,
];

export const VEGGIE_SEEDS_PAIRS = {
	// Mushroom
	[itemsIds.mushroom_spore]: itemsIds.mushroom,
	[itemsIds.mushroom]: itemsIds.mushroom_spore,
	// Carrot
	[itemsIds.carrot_seed]: itemsIds.carrot,
	[itemsIds.carrot]: itemsIds.carrot_seed,
	// Chili Pepper
	[itemsIds.chili_pepper_seed]: itemsIds.chili_pepper,
	[itemsIds.chili_pepper]: itemsIds.chili_pepper_seed,
	// Potato
	[itemsIds.potato_seed]: itemsIds.potato,
	[itemsIds.potato]: itemsIds.potato_seed,
	// Pumpkin
	[itemsIds.pumpkin_seed]: itemsIds.pumpkin,
	[itemsIds.pumpkin]: itemsIds.pumpkin_seed,
	// Tomato
	[itemsIds.tomato_seed]: itemsIds.tomato,
	[itemsIds.tomato]: itemsIds.tomato_seed,
};

export const SPECIES_TO_ENCHANTMENT = {
	human: enchantmentsIds.vigilante,
	elf: enchantmentsIds.elf_exterminator,
	dwarf: enchantmentsIds.luddite,
	giant: enchantmentsIds.giant_slayer,
	golem: enchantmentsIds.technophobe,
	undead: enchantmentsIds.crusader,
	goblin: enchantmentsIds.goblin_slayer,
	beast: enchantmentsIds.big_game_hunter,
	shrimp: enchantmentsIds.shrimpbane,
	chaotic: enchantmentsIds.order_from_chaos,
	demon: enchantmentsIds.exorcist,
	corrupted: enchantmentsIds.purifier,
	woodlike: enchantmentsIds.arboreal_annihilator,
};

export const ENCHANTMENT_TO_SPECIES = {
	[enchantmentsIds.vigilante]: 'human',
	[enchantmentsIds.elf_exterminator]: 'elf',
	[enchantmentsIds.luddite]: 'dwarf',
	[enchantmentsIds.giant_slayer]: 'giant',
	[enchantmentsIds.technophobe]: 'golem',
	[enchantmentsIds.crusader]: 'undead',
	[enchantmentsIds.goblin_slayer]: 'goblin',
	[enchantmentsIds.big_game_hunter]: 'beast',
	[enchantmentsIds.shrimpbane]: 'shrimp',
	[enchantmentsIds.order_from_chaos]: 'chaotic',
	[enchantmentsIds.exorcist]: 'demon',
	[enchantmentsIds.purifier]: 'corrupted',
	[enchantmentsIds.arboreal_annihilator]: 'woodlike',
};

export const FARMING_TRAPS = [
	itemsIds.box_trap,
	itemsIds.pitfall_trap,
	itemsIds.bird_trap,
	itemsIds.net_trap,
	itemsIds.bear_trap,
	itemsIds.monster_trap,
];

export const FARMING_TREE_SEEDS = [
	// Tree Seed
	itemsIds.tree_seed,
	// Oak Tree Seed
	itemsIds.oak_tree_seed,
	// Willow Tree Seed
	itemsIds.willow_tree_seed,
	// Maple Tree Seed
	itemsIds.maple_tree_seed,
	// Yew Tree Seed
	itemsIds.yew_tree_seed,
	// Banana Tree Seed
	itemsIds.banana_tree_seed,
	// Apple Tree Seed
	itemsIds.apple_tree_seed,
	// Elder Tree Seed
	itemsIds.elder_tree_seed,
	// Ancient Oak Seed
	itemsIds.ancient_oak_seed,
];

export const AFFIX_RARITY_OPTIONS = {
	common: { frequency: 100 },
	uncommon: { frequency: 50 },
	rare: { frequency: 25 },
	epic: { frequency: 10 },
	legendary: { frequency: 1 },
	junk: { frequency: 0 },
};

export const COMBAT_SKILLS: TSkillTab[] = ['constitution', 'attack', 'defense', 'strength', 'magic', 'range'];

// Copy TSkillTab
export const ALL_SKILL_NAMES: TSkillTab[] = [
	'mining',
	'foraging',
	'fishing',
	'farming',
	'enchanting',
	'runecrafting',
	'smithing',
	'crafting',
	'cooking',
	...COMBAT_SKILLS,
];

export const AFFIX_REROLL_DUST_COST_MULTIPLIER = 2;
export const AFFIX_CLEAR_DUST_COST_MULTIPLIER = 3;
export const AFFIX_CLEAR_GOLD_COST_MULTIPLIER = 10;
export const AFFIX_CLEAR_SCRAP_COST_MULTIPLIER = 3;
export const AFFIX_EXP_PER_DUST = 10;

export const COMBAT_AFFINITY_CLASH_MIN_MULTIPLIER = 0.2;
export const COMBAT_AFFINITY_CLASH_MAX_MULTIPLIER = 5;
export const COMBAT_BOUND_ANGER_MISSING_HEALTH_THRESHOLD = 0.67;

export const ALL_EQUIPMENT_SLOTS: TEquipmentSlots[] = [
	'helm',
	'cape',
	'necklace',
	'arrows',
	'weapon',
	'body',
	'shield',
	'legs',
	'gloves',
	'boots',
	'ring',
	'pickaxe',
	'hatchet',
	'hoe',
	'tacklebox',
	'tome',
	'tongs',
	'ladle',
	'chisel',
];

export const REFERRAL_TIERS = [1, 2, 3];
export const REFERRAL_TOTAL_LEVEL_REQUIREMENTS = [500, 1000, 15 * 99];
export const REFERRAL_TOTAL_LEVEL_NGIM_REQUIREMENTS = [400, 800, 12 * 99 + 3];

export const COMBAT_ZONE_SETTINGS = {
	startingDifficulty: 'number',
	maxDifficulty: 'number',
	autoEatThreshold: 'number',
	quickStart: 'boolean',
};

// Required for pinning tabs
export const NAVIGATION_TABS = {
	mining: 'Mining',
	foraging: 'Foraging',
	fishing: 'Fishing',
	smithing: 'Smithing',
	farming: 'Farming',
	cooking: 'Cooking',
	crafting: 'Crafting',
	runecrafting: 'Runecrafting',
	enchanting: 'Enchanting',
	constitution: 'Combat',
	attack: 'Combat',
	strength: 'Combat',
	defense: 'Combat',
	range: 'Combat',
	magic: 'Combat',
	marketplace: 'Marketplace',
	'general-shop': 'General Shop',
	'event-shop': 'Event Shop',
	shrine: 'Shrine',
	'platinum-shop': 'Platinum Shop',
	settings: 'Settings',
	achievements: 'Achievements',
	quests: 'Quests',
	talents: 'Talents',
	social: 'Social',
	chat: 'Chat',
	inventory: 'Inventory',
	'session-stats': 'Session Stats',
	'action-queue': 'Action Queue',
};

export const QUEST_TO_TALENT_POINTS = 5;
export const ACHIEVEMENT_TO_TALENT_POINTS = 10;

export const FISHING_HOOKS = [itemsIds.hardened_hook_bait, itemsIds.alluring_lure_bait, itemsIds.improved_hook_bait];

export const TEMPLATE_LOADOUT: ILoadout = {
	id: 0,
	name: 'Empty',
	relatedZones: [],
	foodInventory: [],
	abilityRotation: [abilitiesIds.melee_auto_attack],
	iconItemId: 0,
	equipment: {},
	options: {
		loadoutMercUseFood: true,
	},
};

export const RUNECRAFTING_BASE_PRODUCTION_COUNT = 3;
export const RUNECRAFTING_LEVEL_DIVISOR = 30;
