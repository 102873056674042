import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { smithingActiveForgeAtom } from '../../../../atoms/smithingActiveForgeAtom';
import { smithingActiveBarAtom } from '../../../../atoms/smithingActiveBarAtom';
import { smithingIntensityAtom } from '../../../../atoms/smithingIntensityAtom';
import { itemList } from '../../../../utils/itemList';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import { ISmithingOutput } from '../../../../../../game-server/src/modules/skills/smithing/Smithing.interface';
import { formatNumberToString, getTimeString } from '../../../../helper/helperFunctions';
import { usePlayerAffixStrength, usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import ResourceList from '../../ResourceList';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { CraftingAugmentingData } from '../../CraftingAugmenting/CraftingAugmentingData';
import { cloneDeep } from 'lodash';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import ResourceCost from '../ResourceCost';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { smithingStartAtom } from '../../../../atoms/smithingStartAtom';
import {
	getForge,
	smithingDuration,
	refiningChance,
	refiningTable,
	getBarTier,
	heatCostMultiplier,
	resourceCostMultiplier,
	infernoSpeed,
	smithingExperience,
	smithingOutput,
} from '../../../../utils/smithingFunctions';

export default function SmithingInformation() {
	const activeForge = useRecoilValue(smithingActiveForgeAtom);
	const activeBar = useRecoilValue(smithingActiveBarAtom);
	const intensity = useRecoilValue(smithingIntensityAtom);
	const setCanStart = useSetRecoilState(smithingStartAtom);
	const stockpile = usePlayerField('stockpile');

	const skills = usePlayerField('skills');
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');

	const level = skills.smithing.level;
	const mastery = skills.smithing.masteryLevel;
	const gearLevel = skillEquipmentStats.smithing;
	const effectiveSmithingLevel = level + mastery + gearLevel;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, 'smithing');
	const efficiencyStrength = usePlayerEnchantmentStrength(enchantmentsIds.efficiency, 'smithing');
	const pyromancyStrength = usePlayerEnchantmentStrength(enchantmentsIds.pyromancy, 'smithing');
	const pureMetalsStrength = usePlayerEnchantmentStrength(enchantmentsIds.pure_metals, 'smithing');
	const dwarvenManufacturing = usePlayerEnchantmentStrength(enchantmentsIds.dwarven_manufacturing, 'smithing');
	const elvenLogistics = usePlayerEnchantmentStrength(enchantmentsIds.elven_logistics, 'smithing');
	const refiningStrength = usePlayerEnchantmentStrength(enchantmentsIds.refining, 'smithing');
	const dwarvenRefinementStrength = usePlayerEnchantmentStrength(enchantmentsIds.dwarven_refinement, 'smithing');
	const infernoStrength = usePlayerEnchantmentStrength(enchantmentsIds.inferno, 'smithing');
	const crucibleStrength = usePlayerEnchantmentStrength(enchantmentsIds.crucible, 'smithing');
	const obsidianForgeryStrength = usePlayerEnchantmentStrength(enchantmentsIds.obsidian_forgery, 'smithing');

	const enhancementStrength = usePlayerEnchantmentStrength(enchantmentsIds.forge_enhancement, 'smithing');
	const maintenanceStrength = usePlayerEnchantmentStrength(enchantmentsIds.forge_maintenance, 'smithing');
	const wealthStrength = usePlayerEnchantmentStrength(enchantmentsIds.wealth, 'smithing');
	const scholarStrength = usePlayerEnchantmentStrength(enchantmentsIds.scholar, 'smithing');

	const productionHasteAffix = usePlayerAffixStrength('production.haste');
	const affixHeatCostReduction = usePlayerAffixStrength('smithing.heat_cost_reduction');
	const affixMaterialCostReduction = usePlayerAffixStrength('smithing.material_cost_reduction');

	const activeForgeData = cloneDeep(getForge(activeForge, enhancementStrength, maintenanceStrength));
	const activeBarData = itemList[activeBar];

	const smithingRecipe = CraftingAugmentingData.getSmithingByID(activeBarData.id) ?? {};

	const barTier = getBarTier(activeBarData);

	const duration =
		smithingDuration(
			activeBarData,
			effectiveSmithingLevel,
			activeForge,
			intensity,
			hasteStrength,
			productionHasteAffix,
			enhancementStrength,
			maintenanceStrength,
			elvenLogistics,
			dwarvenManufacturing
		) / 1000;

	const heatMult = heatCostMultiplier(
		intensity,
		activeForgeData,
		barTier,
		affixHeatCostReduction,
		pyromancyStrength,
		crucibleStrength,
		dwarvenManufacturing,
		elvenLogistics
	);

	const resourceMult = resourceCostMultiplier(
		intensity,
		activeForgeData,
		barTier,
		affixMaterialCostReduction,
		pureMetalsStrength,
		crucibleStrength,
		dwarvenManufacturing,
		elvenLogistics
	);

	const outputAmount =
		smithingOutput(activeForgeData, activeBarData, intensity, crucibleStrength) + efficiencyStrength;
	const experience = smithingExperience(activeForgeData, activeBarData, intensity, crucibleStrength);

	const activeBarResourceInput = Object.keys(smithingRecipe);

	const resourceCosts = activeBarResourceInput.map((resourceID) => {
		const itemData = itemList[Number(resourceID)];

		const itemAmount = smithingRecipe[Number(resourceID)];

		const cost = Math.ceil(itemAmount * (itemData.id === itemsIds.heat ? heatMult : resourceMult));

		return { resource: itemData, count: cost };
	});

	const owned: number[] = [];
	for (const input of activeBarResourceInput) {
		owned.push(stockpile.find((item) => item.itemID === Number(input))?.stackSize ?? 0);
	}

	const minAmount = Math.floor(
		Math.min(
			...activeBarResourceInput.map((resourceID, index) => {
				const itemAmount = smithingRecipe[Number(resourceID)];
				const multiplier = resourceID === '2' ? heatMult : resourceMult;
				const cost = Math.ceil(itemAmount * multiplier);
				return owned[index] / cost;
			})
		)
	);

	const infSpeed = infernoSpeed(infernoStrength);

	useEffect(() => {
		setCanStart(minAmount > 0);
	}, [minAmount]);

	function refiningTooltip() {
		const duration = smithingDuration(
			activeBarData,
			effectiveSmithingLevel,
			activeForge,
			intensity,
			hasteStrength,
			productionHasteAffix,
			enhancementStrength,
			maintenanceStrength,
			elvenLogistics,
			dwarvenManufacturing,
			true
		);
		const probability = refiningChance(activeForgeData, duration, refiningStrength, dwarvenRefinementStrength);
		if (probability === 0) {
			return null;
		}

		const table = refiningTable(
			activeForgeData,
			activeBarData,
			intensity,
			refiningStrength,
			dwarvenRefinementStrength,
			obsidianForgeryStrength
		);

		const displayItem = itemList[table[0].id];

		return (
			<IdlescapeWrappingTooltip
				content={
					<div className='resource-container-tooltip'>
						<div className='resource-container-loot-title'>Refining Loot</div>
						<div>{(100 * probability).toFixed(2)}% Chance per action</div>
						<Box className='resource-container-resource-list'>
							<ResourceList resources={table} />
						</Box>
					</div>
				}
			>
				<div className='smithing-information-input'>
					<img src={displayItem.itemImage} alt={displayItem.name} />
					<div />
					<Flex className='smithing-information-input-amount' alignItems='center'>
						Refining <InfoOutlineIcon />
					</Flex>
				</div>
			</IdlescapeWrappingTooltip>
		);
	}

	return (
		<div className='smithing-information idlescape-container'>
			{intensity < barTier && (
				<div className='smithing-furnace-locked-cover'>
					<img src='images/heat_icon.png' className='icon60' alt='Required intensity' />
					<div className='smithing-furnace-locked-cover-level'>{barTier}</div>
				</div>
			)}
			<div className='smithing-information-general'>
				<div className='smithing-information-general-section'>
					<div className='smithing-information-general-section-title'>Forge</div>
					<div className='smithing-information-general-section-content'>{activeForgeData.forgeName}</div>
				</div>
				<div className='smithing-information-general-section'>
					<div className='smithing-information-general-section-title'>Bar</div>
					<div className='smithing-information-general-section-content'>{activeBarData.name}</div>
				</div>
				<div className='smithing-information-general-section'>
					<div className='smithing-information-general-section-title'>Intensity</div>
					<div className='smithing-information-general-section-content'>{intensity}</div>
				</div>
			</div>
			<div className='smithing-information-inputs'>
				<div className='smithing-information-general-section-title'>Input</div>
				<ResourceCost resourceCosts={resourceCosts} amount={minAmount} />
			</div>
			<div className='smithing-information-output'>
				<div className='smithing-information-general-section-title'>
					Output <div className='smithing-totals'>(x{minAmount.toLocaleString('en-us')})</div>
				</div>
				{renderOutputList(
					stockpile,
					{ resourceID: activeBar, amount: outputAmount },
					minAmount,
					wealthStrength,
					scholarStrength
				)}
			</div>
			<div className='smithing-information-calculations'>
				<div className='smithing-information-input'>
					<img src='/images/clock.png' alt='Time per Action' />
					<div></div>
					<div className='smithing-information-input-amount'>
						{getTimeString(duration)}
						<div className='smithing-totals'>
							(~
							{getTimeString((duration * Math.ceil(minAmount)) / (1 + infSpeed), 3)})
						</div>
					</div>
				</div>
				<div className='smithing-information-input'>
					<img src='/images/total_level.png' alt='Experience per Action' />
					<div></div>
					<div className='smithing-information-input-amount'>
						{experience.toLocaleString('en-us')}xp
						<div className='smithing-totals'>(~{(experience * minAmount).toLocaleString('en-us')}xp)</div>
					</div>
				</div>
				{refiningTooltip()}
			</div>
		</div>
	);
}

function renderOutputList(
	stockpile: IItem[],
	output: ISmithingOutput,
	action: number,
	wealthStrength: number,
	scholarStrength: number
) {
	let itemData = itemList[output.resourceID];
	let outputAmount = Math.floor(output.amount);
	let chanceAmount = 1;
	if (wealthStrength) {
		chanceAmount = itemData.value ?? 0;
		outputAmount *= itemData.value ?? 0;
		chanceAmount *= 1 + wealthStrength;
		outputAmount *= 1 + wealthStrength;
		itemData = itemList[itemsIds.gold];
	}
	const ownedItem = stockpile.find((item) => item.itemID === itemData.id);
	const owned = ownedItem?.stackSize ?? 0;
	if (scholarStrength && !wealthStrength) {
		return <Text textAlign='center'>Scholar destroys bars!</Text>;
	}
	return (
		<>
			<OutputMaterial
				itemID={itemData.id}
				outputAmount={outputAmount}
				owned={owned}
				totalAmount={action * output.amount}
			/>
			{output.amount % 1 > 0 && (
				<OutputMaterialChance itemID={itemData.id} outputAmount={chanceAmount} chance={output.amount % 1} />
			)}
		</>
	);
}

function OutputMaterial({
	itemID,
	outputAmount,
	owned,
	totalAmount,
}: {
	itemID: number;
	outputAmount: number;
	owned: number;
	totalAmount: number;
}) {
	const itemData = itemList[itemID];

	const ownedMinified = formatNumberToString(owned);

	return (
		<div className='smithing-information-input' data-itemid={itemID}>
			<img src={itemData.itemImage} className='smithing-information-input-icon' alt={itemData.name} />
			<div className='smithing-information-input-amount'>{formatNumberToString(Math.round(outputAmount))}</div>
			<div className='smithing-information-input-owned'>
				<img src='/images/ui/inventory_icon.png' alt='Inventory amount' className='icon16' />
				{ownedMinified}
				<div className='smithing-totals'>(~{formatNumberToString(Math.round(totalAmount))})</div>
			</div>
			<ItemTooltip item={{ itemID }} />
		</div>
	);
}

function OutputMaterialChance({
	itemID,
	outputAmount,
	chance,
}: {
	itemID: number;
	outputAmount: number;
	chance: number;
}) {
	const itemData = itemList[itemID];

	const percentage = Math.round(chance * 100);

	return (
		<div className='smithing-information-input' data-itemid={itemID}>
			<img src={itemData.itemImage} className='smithing-information-input-icon' alt={itemData.name} />
			<div className='smithing-information-input-amount'>{formatNumberToString(Math.round(outputAmount))}</div>
			<div className='smithing-information-input-owned'>
				<img src='/images/fishing/chance_icon.png' alt='Chance for extra output' className='icon16' />~
				{percentage}%
			</div>
			<ItemTooltip item={{ itemID }} />
		</div>
	);
}
