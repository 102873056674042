import { Flex, Grid, Heading } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import { IdlescapeButton, IdlescapeContainer, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { cloneDeep, isEqual } from 'lodash';
import React from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { FaRegTimesCircle } from 'react-icons/fa';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerField } from '../../../../../hooks/hooks';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { socket } from '../../../../../services/socket.service';
import { isCurrentlyScrapping, sortAugmentingQueue } from '../../../../../utils/augmentingFunctions';
import InventoryItem from '../../../Inventory/InventoryItem';
import { ToggleScrappingQueueDialog } from '../../../Inventory/ItemDialogs/ToggleQueueDialogs';

export default function ScrappingQueue({
	items,
	onClick,
	previewState,
	isDragging,
}: {
	items: IItem[];
	onClick: (event: React.MouseEvent, item: IItem) => boolean;
	previewState: [IItem | null, React.Dispatch<React.SetStateAction<IItem | null>>];
	isDragging: boolean;
}) {
	const [previewItem, setPreviewItem] = previewState;
	const scrapQueue = usePlayerField('scrapQueue');
	const action = usePlayerField('actionQueue');

	const isMobile = useIsMobile();
	const { setNodeRef } = useDroppable({
		id: 'scrapQueue',
		data: { items },
	});

	function handleSortQueue(ascending: boolean) {
		const queueCopy = cloneDeep(scrapQueue);
		const newQueue = sortAugmentingQueue(queueCopy, ascending);
		if (isEqual(newQueue, scrapQueue)) return;
		socket.emit('inventory:scrap:sort', ascending);
	}

	function handleClearQueue() {
		if (scrapQueue.length === 0) return;
		socket.emit('inventory:scrap:clear');
	}

	return (
		<IdlescapeContainer as={Flex} flexDirection='column'>
			<Heading size={'md'} textAlign='center'>
				Research Queue
			</Heading>
			<IdlescapeWrappingTooltip
				content={isCurrentlyScrapping(action) ? 'Queue cannot be modified while scrapping.' : ''}
			>
				<Flex justifyContent='center' flexWrap='wrap'>
					<IdlescapeButton
						variant={isCurrentlyScrapping(action) ? 'disabled' : 'blue'}
						size={isMobile ? 'small' : 'medium'}
						onClick={() => handleSortQueue(true)}
						rightIcon={<AiOutlineArrowUp />}
					>
						Sort {isMobile ? 'Asc.' : 'Ascending'}
					</IdlescapeButton>
					<IdlescapeButton
						variant={isCurrentlyScrapping(action) ? 'disabled' : 'blue'}
						size={isMobile ? 'small' : 'medium'}
						onClick={() => handleSortQueue(false)}
						rightIcon={<AiOutlineArrowDown />}
					>
						Sort {isMobile ? 'Desc.' : 'Descending'}
					</IdlescapeButton>
					<IdlescapeButton
						variant={isCurrentlyScrapping(action) ? 'disabled' : 'red'}
						size={isMobile ? 'small' : 'medium'}
						onClick={() => handleClearQueue()}
						rightIcon={<FaRegTimesCircle />}
					>
						Clear{isMobile ? '' : ' Queue'}
					</IdlescapeButton>
				</Flex>
			</IdlescapeWrappingTooltip>
			<Grid
				gridTemplateColumns={isMobile ? 'repeat(auto-fill, 50px)' : 'repeat(auto-fill, 60px)'}
				gridTemplateRows={isMobile ? 'repeat(auto-fill, 50px)' : 'repeat(auto-fill, 60px)'}
				justifyContent='center'
				gap='0.5rem'
				padding='2px'
				className='anchor-scrap-queue'
				border={isDragging ? '2px dashed rgb(208, 108, 238)' : '2px solid transparent'}
				borderRadius='5px'
				flex={isDragging ? '1 1 100%' : undefined}
				ref={setNodeRef}
				style={{ touchAction: 'none' }}
			>
				{items.map((item, index) => {
					const selected = item.id === previewItem?.id;
					const boxShadow = selected
						? '0 0 5px 3px rgb(208, 108, 238), inset 0 0 2px 5px rgb(208, 108, 238)'
						: index === 0
						? '0 0 3px 1px rgba(216, 198, 221, 0.7), inset 0 0 1px 3px rgba(216, 198, 221, 0.7)'
						: undefined;
					return (
						<InventoryItem
							key={item.id}
							item={item}
							overwriteDialog={ToggleScrappingQueueDialog}
							overrideOnClick={(e) => onClick(e, item)}
							onPointerEnter={() => {
								if (index !== 0) setPreviewItem(item);
							}}
							onPointerLeave={() => setPreviewItem(null)}
							boxShadow={boxShadow}
							inventoryName={'scrapQueue'}
							extraClassNames={selected ? 'item-animating-pink' : undefined}
							showTooltip={false}
							updaterList={[selected]}
						/>
					);
				})}
			</Grid>
		</IdlescapeContainer>
	);
}
